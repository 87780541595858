//
// Screen resolutions
//---------------------------------------------------
    // Extra small devices
    @media (max-width: @screen-xs-max) { 
    }

    // Small devices
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    }

    // Medium devices
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    }

    // Large devices
    @media (min-width: @screen-lg-min) {
    }

    // Small devices and Extra small devices
    @media (max-width: @screen-sm-max) {
    }

    // Large devices and Medium device
    @media (min-width: @screen-md-min) {
    }

    // Medium devices and Small devices and Extra small devices
    @media (max-width: @screen-md-max) {
    }


//
// Body
//---------------------------------------------------
    body {
        background-color: @greyColor;
        font-family: "open-sans",sans-serif;
        overflow-x: hidden;
        color: @whiteColor;
    }

//
// Typography
//---------------------------------------------------
    h1, h2, h3, h4 {
      
    }

    h1 {

    }

    h2 {
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;
        color: @orangeColor;  
        margin: 40px 0 30px 0;
    }

    h3 {
        
    }

    p {
        
    }

    ul {
        > li {
        }
            
    }

    a {
        &:hover, &:focus {
            
        }
    }

//
// Essential classes
//---------------------------------------------------
    .affix, .affix-top {
        position: fixed !important;
        z-index: 999 !important;
    }

    .page-header {
        border-color: #404040;
        margin-bottom: 45px;
    }

    .no-padding {
        padding: 0;
    }

    .form-padding {
        padding: 0 15px;
    }

    .gallery-padding {
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: 20px;
    }

    .pricelist-margin {
        margin-bottom: 20px;
    }

    .clear:after {
        clear: both;
        content: "";
        display: table;
    }
    .equal {
        display: flex;
        flex-wrap: wrap;
    }

    .equal > div[class*='col-'] {  
        display: flex;
        flex-direction: column;
        // Extra small devices
        @media (max-width: @screen-xs-max) {
            width: 100%;
        }
    }
    .equal > .col-md-4 {  
        display: flex;
        flex-direction: column;
        // Extra small devices
        @media (min-width: @screen-md-min) {
            width: 33.22%;
        }
    }


//
// Header
//---------------------------------------------------
    .header {
        width: 100%;
    }

//
// Footer
//---------------------------------------------------
    .footer {
        background-color: #2b2b2b;
        text-align: center;
        padding: 15px 0 20px 0;
        border-top: 1px solid #3a3a3a;
        // Large devices and Medium device
        @media (min-width: @screen-md-min) {
            text-align: right;
        }
    }
//
// Navbar
//---------------------------------------------------
.collapsing {
    position: initial;
}

.navbar {
    margin-bottom: 0;
    background: transparent;
    border: 0;
    &-default .navbar-nav {
        width: auto;
        margin-top: 31px;
        li {
            a {
                font-size: 16px;
                font-weight: 400;
                color: @whiteColor;   
                border-bottom: 1px solid transparent;
                background: none;
                padding: 10px 13px 10px 13px;
                margin: 0;  
                // Large devices and Medium device
                @media (min-width: @screen-md-min) {
                    padding: 0 13px 6px 11px;
                    margin: 0 8px;     
                    border-bottom: 5px solid transparent;
                }
            }

            &.active>a, &.active>a:hover, &.active>a:focus, >a:hover, >a:focus {
                    color: @whiteColor;
                    border-bottom: 1px solid #d68107;
                    background: @orangeColor;   
                    // Large devices and Medium device
                    @media (min-width: @screen-md-min) {
                        border-bottom: 5px solid @orangeColor;
                        background: none;
                    }                     
            } 
            &.active>a:focus:not(:first-child), >a:hover:not(:first-child), >a:focus:not(:first-child) {
                    border-color: transparent;                        
            } 
        }
    }
    &-brand {
        height: auto;
        padding: 15px 10px;
        margin-bottom: -109px;

        img {
            margin-top: 20px;
            max-width: 175px;
            // Small devices
            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                margin-top: 15px;
                max-width: 245px;
            }
            // Large devices and Medium device
            @media (min-width: @screen-md-min) {
                margin-top: 0;
                max-width: 100%;
            }
        }
    }

    &-right {
            // Small. Medium and Large devices
            @media (min-width: @screen-md-min) {
                margin-right: -20px;
            }
    }

    &-collapse {
            background-color: @greyColor;
            border: 1px solid @blackColor !important;
            border-bottom-color: #373737 !important;
            width: 100%;
            position: relative;
            top: -10px;
            // Small. Medium and Large devices
            @media (min-width: @screen-md-min) {
                background-color: transparent;
                border: 0 !important;
                position: inherit;
                top: inherit;
                float: right;
                width: auto;
            }

    }

    &-toggle {
        margin-top: 28px;
        margin-bottom: 25px;
    }
}

//
// Lines
//---------------------------------------------------

.orange {
    &--header {
        &:before, &::before {
            content: "";
            display: block;
            position: absolute;
            width: 120%;
            height: 130px;
            top: -19px;
            left: -10%;
            right: -10%;
            background-color: @greyColor;
            border-bottom: 5px solid @orangeColor;
            transform: rotate(358deg);
            -webkit-transform: rotate(358deg);
            -moz-transform: rotate(358deg);
            -o-transform: rotate(358deg);
            -ms-transform: rotate(358deg); 
            -webkit-box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);
            -moz-box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);
            box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);    
            // Large devices and Medium device
            @media (min-width: @screen-md-min) {
                top: -35px;
            }
        }
    }
    &--slider {
        &:before, &::before {
            content: "";
            display: block;
            position: absolute;
            width: 120%;
            height: 100px;
            bottom: -62px;
            left: -10%;
            right: -10%;
            z-index: 2;            
            background-color: #2b2b2b;
            border-top: 5px solid @orangeColor;
            transform: rotate(358deg) ;
            -webkit-transform: rotate(358deg) ;
            -moz-transform: rotate(358deg) ;
            -o-transform: rotate(358deg) ;
            -ms-transform: rotate(358deg) ;     
        }
    }
    &--cenik {
        &:before, &::before {
            content: "";
            display: block;
            position: absolute;
            width: 120%;
            height: 70px;
            bottom: -142px;
            left: -10%;
            right: -10%;
            z-index: -1;            
            background-color: #2b2b2b;
            border-top: 5px solid @orangeColor;
            transform: rotate(358deg) ;
            -webkit-transform: rotate(358deg) ;
            -moz-transform: rotate(358deg) ;
            -o-transform: rotate(358deg) ;
            -ms-transform: rotate(358deg) ;  
            -webkit-box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);
            -moz-box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);
            box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);     
        }
    }
    &--lightbottom {
        &:before, &::before {
            content: "";
            display: block;
            position: absolute;
            width: 120%;
            height: 100px;
            bottom: -62px;
            left: -10%;
            right: -10%;
            z-index: -1;            
            background-color: #2b2b2b;
            transform: rotate(358deg) ;
            -webkit-transform: rotate(358deg) ;
            -moz-transform: rotate(358deg) ;
            -o-transform: rotate(358deg) ;
            -ms-transform: rotate(358deg) ;  
            -webkit-box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);
            -moz-box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);
            box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);    
        }
    }
    &--darkbottom {
        &:before, &::before {
            content: "";
            display: block;
            position: absolute;
            width: 120%;
            height: 100px;
            bottom: -172px;
            left: -10%;
            right: -10%;
            z-index: -1;            
            background-color: #2b2b2b;
            transform: rotate(358deg) ;
            -webkit-transform: rotate(358deg) ;
            -moz-transform: rotate(358deg) ;
            -o-transform: rotate(358deg) ;
            -ms-transform: rotate(358deg) ;  
            -webkit-box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);
            -moz-box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);
            box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.5);    
        }
    }
}

//
// BEMS
//---------------------------------------------------
.section {
    width: 100%;
    position: relative;

    &--slider {
        min-height: 670px;
        z-index: 0;
        padding-top: 42px;
        // Large devices and Medium device
        @media (min-width: @screen-md-min) {
        padding-top: 58px;
        }
    }

    &--light {
        background-color: #2b2b2b;
        padding-bottom: 5px;
        margin-bottom: 100px;
        &:last-child {
            color: red;

                margin-bottom: 0;

        }
    }

    &--dark {
        padding-bottom: 5px;
        margin-bottom: 110px;
    }
    &:last-of-type {
        &.section--light {
            margin-bottom: 0;
            padding-bottom: 20px;
        }
    }
}

.service {
    &--image {
        img {
            margin: 0 auto;
        }
    }

    &--title {
        color: @orangeColor;
        margin: 15px auto;
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        min-height: 56px;
        display: table;
        .middle {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &--text {
        margin: 0 auto 50px auto;

        // Medium and Large devices
        @media (min-width: @screen-md-min) {
            margin: 0 auto;
        }
    }

    &:last-child {
        .service--text {margin-bottom: 0;}
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 40px 0;
        text-align: left;
        display: inline-block;  
        
        li {
            padding-left: 16px;
            &:before, &::before {
                content: "\f105";
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;
                color: @orangeColor;
                font-size: 16px;
                padding-right: 10px;
                margin-left: -16px;
            }
        }
    }
}

//
// Owl carousel fix
//---------------------------------------------------
.owl-carousel {
    z-index: 1;
}

.owl-wrapper-outer {
    z-index: 1;
}

.owl-stage {
    z-index: 1;
}

.owl-stage-outer {
    z-index: 2;
    width: 100%;
}

.owl-item {
    z-index: 1;
}

//
// Slider
//--
.wrapper-slider {
  position: relative;
}

.slide {
    background-image: url(../img/1920x600.png);
    background-position: center;
    height: 670px;
}

.owl-nav {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: ~"calc(-137px / 2)";
    height: 0;
    overflow: visible;


    // Small devices
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        left: -15px;
        right: -15px;
        margin-top: ~"calc(-137px / 2)";
    }

    // Medium devices
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        left: -10px;
        right: -10px;
        margin-top: ~"calc(-137px / 2)";
    }

    // Large devices
    @media (min-width: @screen-lg-min) {
        left: -50px;
        right: -50px;
        margin-top: ~"calc(-137px / 2)";
    }

    .owl-prev, .owl-next {
        font-size: 0;
            &:before, &::before {
                content: "";
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;
                color: @orangeColor;
                font-size: 96px;
                font-weight: 800;
            }
    }

    .owl-prev {
        float: left;
        &:before, &::before {
            content: "\f104";
        }
    }

    .owl-next {
        float: right;
        &:before, &::before {
            content: "\f105";
        }
    }
}

.slide-box {
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 1);

    .button {
        display: inline-block;
        padding: 13px 15px;
        margin-top: 25px;
        background-color: @orangeColor;
        font-size: 18px;
        font-weight: 700;
        color: @whiteColor;
        text-transform: uppercase;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
        
        &:hover, &:focus {
            text-decoration: none;
        }
        
        &:after, &::after {
            content: "»";
            padding-left: 10px;
            vertical-align: text-bottom;
        }
    }
}

.slide-big {
    display: inline-block;
    font-size: 60px;
    font-weight: 700;
    color: @whiteColor;
    padding: 0;
    margin-top: 200px;

    h1 {
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        border: 0;
        padding: 0;
        margin: 0 0 25px 0;
    }
}

.slide-small {
    display: block;
    font-size: 17px;
    font-weight: 400;
    color: @whiteColor;
    padding: 0;

}

// Extra small devices
@media (max-width: @screen-xs-max) {
    .slide {
        padding-top: 0;
    }
}

//
// Articles
//---------------------------------------------------

    .article-item {
        margin-bottom: 30px;
        &__text {
            text-align: center;
            line-height: 1.7;
            // Medium devices
            @media (min-width: @screen-md-min) {
                margin-top: 60px;
                text-align: left;
            }
        }
        &__image {
            font-size: 0;
            @media (min-width: @screen-sm-min) {
                picture:nth-child(3n-1) img {
                    margin: 0 9px 9px 9px;
                }
            }

            img { 
                margin: 15px auto;
                -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
                @media (min-width: @screen-sm-min) {
                    display: inline-block;
                    margin: 0 auto 9px auto;
                }                
            }

        }
    }

//
// Gallery
//---------------------------------------------------

    .gallery-pic {
        text-align: center;
        position: relative;
        display: block;
        margin-bottom: 5px;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
        overflow: hidden;

        &:hover, &--active {
            .gallery-pic__image:after, .gallery-pic__image::after {
                background-color: rgba(0, 0, 0, 0.6);
            }
            .gallery-pic__overlay {               
                top: 55%;
                opacity: 1;
            }
        }

        &__image {
            &:after, &::after {
                content: "";
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                -webkit-transition: all 0.3s;
                -o-transition: all 0.3s;
                transition: all 0.3s;
            }
        }
        &__overlay {
            display: inline-block;
            position: absolute;
            top: 150%;
            left: 0;
            right: 0;
            transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -webkit-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            opacity: 0;
            a {
                font-size: 32px;
                color: @gallery-picOverlayColor;
                padding: 6px;

                // Medium devices
                @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
                    font-size: 64px;
                    padding: 12px;
                }

                &:hover {
                    color: @gallery-picOverlayColorHover;
                    text-decoration: none;
                }
            }
        }  
    }

//
// Pricelist
//---------------------------------------------------

    .pricelist-item {
        text-align: center;   
        // Large devices
        @media (min-width: @screen-xs-min) {
            text-align: left;
        }

        &__image {
            width: 95px;
            display: block;
            margin: 0 auto;
            -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
            // Large devices
            @media (min-width: @screen-xs-min) {
                float: left;
                margin-right: 15px;
            }
        }

        &__text {
            font-size: 15px;
            line-height: normal;
            width: 100%;
            margin-bottom: 20px;
            // Large devices
            @media (min-width: @screen-xs-min) {
                float: left;
            }
        }

        &__head {
            border-bottom: 1px solid #bebebe;
            color: @orangeColor;
            padding-bottom: 5px;
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: bold;  

            &--title {
                display: block;
                // Large devices
                @media (min-width: @screen-xs-min) {
                    float: left;
                }
                              
            }
            &--price {
                display: block;                
                // Large devices
                @media (min-width: @screen-xs-min) {
                    float: right;
                }
            }
        }

        img[src] {
            margin-top: 20px;
            margin-bottom: 15px;

            @media (min-width: @screen-xs-min) {
                &+.pricelist-item__text {
                    width: ~"calc(100% - 110px)";
                }
            }

            &+.pricelist-item__text > .pricelist-item__head {
                border-bottom: 0;
                margin-top: 20px;
            }

            &+.pricelist-item__text p {
                // Large devices
                @media (min-width: @screen-xs-min) {
                    width: ~"calc(100% - 60px)";
                }
                
            }

            &+.pricelist-item__text .pricelist-item__head--price {
                // Large devices
                @media (min-width: @screen-xs-min) {
                    position: absolute;
                    right: 25px;
                    top: 50%;
                    transform: translate(0, -50%);
                    -webkit-transform: translate(0, -50%);
                    -moz-transform: translate(0, -50%);
                    -o-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                }

            }

            &+.pricelist-item__text::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 25px;
                right: 25px;
                border-bottom: 1px solid @orangeColor;
            }
        }        
    }

//
// Contacts
//--

    .contact-item {
        &__title {
            font-size: 25px;
            text-transform: none;
        }
        &__text {
            font-size: 0;
            > ul {
                padding: 0;
                vertical-align: top;
                > li {
                    font-size: 14px;
                    display: block;
                    padding: 0 30px 0px 40px;
                    margin-bottom: 15px;
                    position:relative;
                    min-height:25px;
                    &:before, &::before {
                        font: normal normal normal 14px/1 FontAwesome;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        top: 50%;
                        transform: translate(0, -50%);
                        -webkit-transform: translate(0, -50%);
                        -moz-transform: translate(0, -50%);
                        -o-transform: translate(0, -50%);
                        -ms-transform: translate(0, -50%);
                        font-size: 22px;
                        content: "";
                        position:absolute;
                        left:0;        
                        color: @contactButtonColor;               
                    }
                    &.map-marker {&:before, &::before{content: "\f041";}}
                    &.earphone {&:before, &::before{content: "\f095";margin-top: -1px;}}
                    &.envelope {&:before, &::before{content: "\f0e0";margin-top: -3px;}}
                }
            }
        }
        &__footer {
            padding: 30px 10px 0 0;
            border-top: 1px solid @orangeColor;       
            margin-bottom: 20px;  
        }
        &__btn {
            display: inline-block;
            padding: 13px 10px;
            background-color: @orangeColor;
            font-size: 18px;
            font-weight: 700;
            color: @whiteColor;
            text-transform: uppercase;
            border: 0;
            max-width: 100%;


            @media (min-width: @screen-sm-min) {
                padding: 13px 35px;
            }            

            &:hover, &:focus {
                text-decoration: none;
            }
            
            &:after, &::after {
                content: "»";
                padding-left: 10px;
                vertical-align: text-bottom;
            }
        }

        .form-control {
            background-color: #202020;
            border-color: #3a3a3a;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            line-height: 2;
            height: 53px;
            color: @whiteColor;
            
            &::-webkit-input-placeholder {
               color: #bebebe;
            }

            &:-moz-placeholder { /* Firefox 18- */
               color: #bebebe;
            }

            &::-moz-placeholder {  /* Firefox 19+ */
               color: #bebebe;
            }

            &:-ms-input-placeholder {  
               color: #bebebe;
            }

        }
        textarea.form-control {
            height: 100px;
        }
    }
